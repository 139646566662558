import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    marginRight: 10,
    height: 175
  },
  title: {
    fontWeight: 600,
    fontSize: 17,
    color: theme.palette.primary.main
  },
  subheader: {
    fontWeight: 400,
    flex: 1,
    fontSize: 16,
    marginTop: 20,
    marginLeft: 10
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 60,
    width: 60
  }
}));

const CardEtiqueta = (props) => {
  const { className, etiqueta, selectGroup, handleCheck, isChecked, ...rest } = props
  const classes = useStyles();

  const [checked, setChecked] = useState(isChecked)

  const handleChangeCheck = () => {
    const currentCheck = !checked

    setChecked(currentCheck)
    handleCheck(currentCheck, etiqueta.id)
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
          <Grid item lg={10} sm={10} xl={10} xs={10}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              <Typography component="h1" variant="h4">
                {(etiqueta.totvsInsumo != undefined ? etiqueta.totvsInsumo.nome.substring(0, 20) : '')}
              </Typography>
            </Typography>

            <Divider style={{ backgroundColor: '#263238', height: 2, marginTop: 5 }} />
          </Grid>
          <Grid item lg={2} sm={2} xl={2} xs={2}>
            <img
              width="40px"
              height="40px"
              alt="Logo"
              src="/images/logo_simbolo_preto.svg"
            />
          </Grid>
         
          <Grid container justifyContent="space-between" style={{ marginTop: 10, marginBottom: 5 }}>
            <Grid item lg={6} sm={6} xl={6} xs={6} style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Typography>Produção</Typography>
              <Typography>Validade</Typography>
              <Typography>Responsável</Typography>
            </Grid>

            <Grid item lg={6} sm={6} xl={6} xs={6} style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'right',
              alignItems: 'right',
              justifyContent: 'right'
            }}>
              <Typography>{moment(etiqueta.data).format('DD/MM/YYYY')}</Typography>
              <Typography>{moment(etiqueta.dataValidade).format('DD/MM/YYYY')}</Typography>
              <Typography>{etiqueta.responsavel}</Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              {selectGroup &&
                <Checkbox
                  checked={checked}
                  onChange={handleChangeCheck}
                  size="small"
                />
              }
            </Grid>

            <Grid item lg={6} sm={6} xl={6} xs={6} style={{
              textAlign: 'right',
              alignItems: 'right',
              justifyContent: 'right'
            }}>
              <Typography>{'#' + etiqueta.codigoSerial}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CardEtiqueta.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  remove: PropTypes.element
}

export default CardEtiqueta
