import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const fornecedorAction = {
  getFornecedor,
  addFornecedor,
  changeDetailsFornecedor,
  getFornecedorSelect,
  getFornecedorSelectLoja,
  getFornecedoresByGrupoId,
  getFornecedorById,
  onChangeProps,
  editFornecedorInfo,
  editFornecedorGrupo,
  editFornecedorApiKeyInfo,
  editFornecedoresDetails,
  createFornecedor,
  deleteFornecedorById,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getFornecedor(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'fornecedores' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeFornecedoresList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createFornecedor(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'fornecedores/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createFornecedorInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/fornecedores')
        } else {
          toast.err('Oops! Erro ao cadastrar fornecedor! 😥')
        }
      }).catch((err) => {
        // console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar fornecedor! 😥')
        return err.response;
      })
  }
}

function changeDetailsFornecedor(fornecedor) {
  return (dispatch) => {
    if (fornecedor) {
      dispatch(onChangeDetailsFornecedor(fornecedor))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsFornecedor(fornecedor) {
  return [
    {
      type: 'CHANGE_DETAILS_FORNECEDOR',
      id: fornecedor.id,
      nome: fornecedor.nome
    }
  ]
}

function getFornecedorSelect() {
  return (dispatch) => {
    let apiEndpoint = 'fornecedores/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeFornecedoresList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getFornecedorSelectLoja(id) {
  return (dispatch) => {
    let apiEndpoint = 'fornecedores/select/loja/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeFornecedoresList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getFornecedoresByGrupoId(id) {
  return (dispatch) => {
    let apiEndpoint = 'fornecedores/grupo/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(changeFornecedoresList(response.data))
    })
  }
}

function getFornecedorById(id) {
  return (dispatch) => {
    let apiEndpoint = 'fornecedores/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editFornecedoresDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editFornecedorInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'fornecedores/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedFornecedorInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/fornecedores')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar fornecedor! 😥')
        //  console.log(err.response);
        return err.response;
      }
      )
  }
}

function editFornecedorGrupo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'fornecedores/grupo/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedFornecedorInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao inserir fornecedor ao grupo! 😥')
        return err.response;
      })
  }
}

function editFornecedorApiKeyInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'fornecedores/chaveIntegracaoAPI/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedFornecedorInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/fornecedores')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar fornecedor! 😥')
        //  console.log(err.response);
        return err.response;
      }
      )
  }
}

function deleteFornecedorById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'fornecedores/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteFornecedoresDetails())
      dispatch(getFornecedor(filterModel))
      dispatch(notIsLoading())
      history.push('/fornecedores')
    })
  }
}

export function addFornecedor(payload) {
  return [{ type: 'ADD_FORNECEDOR', fornecedor: payload }, clear()]
}

export function changeFornecedoresList(fornecedor, totalRows) {
  return {
    type: 'FETCHED_ALL_FORNECEDOR',
    fornecedor: fornecedor,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'FORNECEDOR_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'FORNECEDOR_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'FORNECEDOR_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editFornecedoresDetails(fornecedor) {
  return [
    { type: 'FORNECEDOR_DETAIL', fornecedor },
  ]
}

export function updatedFornecedorInfo() {
  return {
    type: 'FORNECEDOR_UPDATED'
  }
}

export function createFornecedorInfo() {
  return {
    type: 'FORNECEDOR_CREATED_SUCCESSFULLY'
  }
}

export function deleteFornecedoresDetails() {
  return {
    type: 'DELETED_FORNECEDOR_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'FORNECEDOR_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'FORNECEDOR_NOTISLOADING'
  }
}
