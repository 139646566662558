import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { grupoFornecedoresAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const GrupoFornecedores = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { grupoFornecedores, totalRegistros } = props.grupoFornecedores
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const { page, limit, term, direction, order } = useSelector(state => state.grupoFornecedores);
  const [debounceTerm] = useDebounce(term, 1000)

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  useEffect(() => {
    handleChangePage(null, page);
    fetchData();
  }, [idLoja]);

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'nome',
      Direction: direction || 'asc'
    };

    dispatch(grupoFornecedoresAction.getGrupoFornecedores(filterModel, true));
  };

  const handleChange = prop => event => {
    dispatch(grupoFornecedoresAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(grupoFornecedoresAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.grupoFornecedores.page = 0;
    dispatch(grupoFornecedoresAction.onChangeProps('term', fakeEvent))
  }

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Grupo de Fornecedores?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, excluir!',
      disabled: props.grupoFornecedores.isLoading,
      confirmationText: props.grupoFornecedores.isLoading ? 'Excluindo grupo...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(grupoFornecedoresAction.deleteGrupoFornecedoresById(id))
    })
  }

  return (
    <Page className={classes.root} title="Grupos de Fornecedores">
      <AccessControl
        rule={'gruposFornecedores'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            <TitleContent
              rule={'fornecedores.create'}
              length={undefined !== grupoFornecedores && grupoFornecedores.length > 0 ? grupoFornecedores.length : 0}
              subTitle={'GERÊNCIA DE GRUPOS DE FORNECEDORES'}
              title={'Lista de Grupos de Fornecedores'}
              href={'/grupoFornecedores'}
            />

            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Filtro"
                value={term}
                onChange={(event) => handleChangeTerm(event.target.value)}
                fullWidth
              />
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== grupoFornecedores && grupoFornecedores.length
                    ? grupoFornecedores.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.nome}
                            </TableCell>
                            <TableCell align="right">
                              <AccessControl
                                rule={'fornecedores.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/grupoFornecedores/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title={'Editar'}>
                                      <Tooltip>
                                        <EditIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'fornecedores.delete'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() => handleClick(n.id)}
                                    size="small"
                                  >
                                    <Tooltip title="Excluir">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}

GrupoFornecedores.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    grupoFornecedores: state.grupoFornecedores
  }
}

const connectedGrupoFornecedoresPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(GrupoFornecedores))
)

export { connectedGrupoFornecedoresPage as GrupoFornecedores }
