import React, { useEffect, useState, createRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import { grupoFornecedoresAction, fornecedorAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import { EditorHeader, Page, PaginationActions } from 'components'
import { AccessControl } from '../../components/Utils/AccessControl'
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete'
import { useConfirm } from 'material-ui-confirm'
import { isEmpty } from 'lodash'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  }
})

const GrupoFornecedoresEdit = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const formRef = createRef(null);
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { fornecedor, totalRegistros } = props.fornecedor
  const { page, limit, term, direction, order } = useSelector(state => state.fornecedor);

  const [open, setOpen] = useState(false);
  const [fornecedorId, setFornecedorId] = useState(0);

  useEffect(() => {
    fetchData()
  }, []);

  useEffect(() => {
    dispatch(grupoFornecedoresAction.getGrupoFornecedoresById(params.id))
  }, [props.fornecedor]);

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  const fetchData = () => {
    if (params.id) {
      dispatch(grupoFornecedoresAction.getGrupoFornecedoresById(params.id))
    } else {
      dispatch(grupoFornecedoresAction.clear())
    }
  };

  const handleChange = (prop) => (event) => {
    dispatch(grupoFornecedoresAction.onChangeProps(prop, event))
  }

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(fornecedorAction.onChangeProps('page', fakeEvent))
  }

  const handleSubmit = (event) => {
    let payload = {
      id: params.id,
      nome: props.grupoFornecedores.nome,
    }

    if (params.id) {
      dispatch(grupoFornecedoresAction.editGrupoFornecedoresInfo(params.id, payload, true))
    } else {
      dispatch(grupoFornecedoresAction.createGrupoFornecedores(payload))
    }

    dispatch(fornecedorAction.getFornecedoresByGrupoId(params.id))
  };

  const handleSubmitFornecedor = (event) => {
    const payload = {
      IdGrupoFornecedores: params.id
    }

    dispatch(fornecedorAction.editFornecedorGrupo(fornecedorId, payload))

    handleClose()
  };

  const handleRemoveFornecedor = (id) => {
    confirm({
      title: 'Você deseja remover este Fornecedor?',
      description: '',
      confirmationText: 'Sim, remover!',
      disabled: props.grupoFornecedores.isLoading,
      confirmationText: props.grupoFornecedores.isLoading ? 'Removendo fornecedor...' : 'Sim, remover!',
      cancellationText: 'Não!'
    }).then(() => {
      const payload = {
        IdGrupoFornecedores: null
      }

      dispatch(fornecedorAction.editFornecedorGrupo(id, payload))
    })
  }

  const handleClickOpen = (event) => {
    dispatch(fornecedorAction.getFornecedorSelect())

    event.preventDefault()
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFornecedorId(0)
  };

  const handleChangeFornecedorId = (event) => {
    setFornecedorId(event.target.value)
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Novo Grupo de Fornecedores'}</b>
      </Typography>
    )
  }

  function FornecedoresText() {
    return (
      <Typography variant="h4">
        <b>{'Fornecedores'}</b>
      </Typography>
    )
  }

  function SegHeaderFornecedores() {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
        <FornecedoresText />
        <Button
          color="primary"
          variant="contained"
          disabled={!params.id}
          onClick={(event) => handleClickOpen(event)}
        >
          NOVO
        </Button>
      </Box>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Grupos - Editar Grupo de Fornecedores'
          : 'Gerência de Grupos - Novo Grupo de Fornecedores'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formGrupoFornecedores"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Grupo de Fornecedores"
                  title={(
                    params.id ?
                      <EditorHeader title='Editar Grupo de Fornecedores' tabela='GrupoFornecedores' chave={params.id} props={props} />
                      :
                      <InsertText />
                  )}
                />
                <Divider />
                <CardContent >
                  <Grid container spacing={2} alignItems="center" >
                    <Grid item lg={9} md={9} xl={9} xs={12}>
                      <TextValidator
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Nome *"
                        value={props.grupoFornecedores.nome}
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        id="nome"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/gruposFornecedores')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.grupoFornecedores.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.grupoFornecedores.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.grupoFornecedores.isLoading ? 'Atualizando grupo...' : 'Atualizar') : (props.grupoFornecedores.isLoading ? 'Salvando grupo...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
            <br />
            <Card style={{ marginTop: '12px' }}>
              <CardContent>
                <CardHeader
                  title={<SegHeaderFornecedores />}
                  subheader="Lista de Fornecedores"
                />
                <Divider />
                <Grid item lg={12} md={12} xl={12} xs={12} align="center" style={{ marginTop: '15px', marginBottom: '20px' }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nome</TableCell>
                          <TableCell align="right">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {undefined !== props.grupoFornecedores.fornecedores && props.grupoFornecedores.fornecedores.length
                          ? props.grupoFornecedores.fornecedores.map((f) => {
                            return (
                              <TableRow hover key={f.id}>
                                <TableCell component="th" scope="row">
                                  {f.nome}
                                </TableCell>
                                <TableCell align="right">
                                  <AccessControl
                                    rule={'gruposFornecedores.delete'} //permissão necessária para acessar conteúdo
                                    yes={() => (
                                      <IconButton
                                        aria-label="Delete"
                                        onClick={() => handleRemoveFornecedor(f.id)}
                                        size="small"
                                      >
                                        <Tooltip title="Excluir">
                                          <DeleteIcon />
                                        </Tooltip>
                                      </IconButton>
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={props.grupoFornecedores.fornecedores.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChange('limit')}
                    ActionsComponent={PaginationActions}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <ValidatorForm
          className={classes.form}
          ref={formRef}
          id="formGrupoFornecedores"
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogContent>
            <DialogContentText>{'Adicionar Fornecedor'}</DialogContentText>
            <SelectValidator
              validators={['required']}
              errorMessages={['Campo obrigatório!']}
              variant="outlined"
              id="idGrupoFornecedores"
              label="Fornecedor *"
              value={fornecedorId}
              onChange={handleChangeFornecedorId}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              displayEmpty
              labelId="idGrupoFornecedores"
            >
              <MenuItem disabled value="" key={12}>
                <em>Fornecedor *</em>
              </MenuItem>
              {!isEmpty(fornecedor) &&
                undefined !== fornecedor &&
                fornecedor.length &&
                fornecedor.map((row, index) => (
                  <MenuItem key={'u' + index} value={row.value}>
                    {row.text}
                  </MenuItem>
                ))}
            </SelectValidator>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              onClick={handleClose}
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              onClick={handleSubmitFornecedor}
              variant="contained"
            >
              Adicionar
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </Page>
  )
}

GrupoFornecedoresEdit.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return state
}

const connectedGrupoFornecedoresEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(GrupoFornecedoresEdit))
)

export { connectedGrupoFornecedoresEditPage as GrupoFornecedoresEdit }
