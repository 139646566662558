import { authentication } from './auth.reducer'
import { bling } from './bling.reducer'
import { banner } from './banner.reducer'
import { campanha } from './campanha.reducer'
import { categoriaCompra } from './categoriaCompra.reducer'
import { categoriaInsumo } from './categoriaInsumo.reducer'
import { certificado } from './certificado.reducer'
import { checklist } from './checklist.reducer'
import { checklistOperacao } from './checklistOperacao.reducer'
import { checklistOperacaoItem } from './checklistOperacaoItem.reducer'
import { checklistTotvsInsumoUnidade } from './checklistTotvsInsumoUnidade.reducer'
import { cidade } from './cidade.reducer'
import { cliente } from './cliente.reducer'
import { compra } from './compra.reducer'
import { cupomProduto } from './cupomProduto.reducer'
import { dashboard } from './dashboard.reducer'
import { dashboardCompra } from './dashboardCompra.reducer'
import { estado } from './estado.reducer'
import { etiqueta } from './etiqueta.reducer'
import { fidelizacao } from './fidelizacao.reducer'
import { fornecedor } from './fornecedor.reducer'
import { fornecedorFilial } from './fornecedorFilial.reducer'
import { fornecedorInfo } from './fornecedorInfo.reducer'
import { grupo } from './grupo.reducer'
import { grupoFornecedores } from './grupoFornecedores.reducer'
import { log } from './log.reducer'
import { listaPreco } from './listaPreco.reducer'
import { loja } from './loja.reducer'
import { mensagem } from './mensagem.reducer'
import { meta } from './meta.reducer'
import { notificacao } from './notificacao.reducer'
import { notificacaoFiltro } from './notificacaoFiltro.reducer'
import { notificacaoUsuario } from './notificacaoUsuario.reducer'
import { parametro } from './parametro.reducer'
import { pedidoCompra } from './pedidoCompra.reducer'
import { permissao } from './permissao.reducer'
import { permissaoTipoUsuario } from './permissaoTipoUsuario.reducer'
import { permissaoUsuario } from './permissaoUsuario.reducer'
import { pontuacao } from './pontuacao.reducer'
import { produto } from './produto.reducer'
import { produtoSelect } from './produtoSelect.reducer'
import { relatorio } from './relatorio.reducer'
import { respostaChecklistOperacao } from './respostaChecklistOperacao.reducer'
import { retrospectiva } from './retrospectiva.reducer'
import { sorteio } from './sorteio.reducer'
import { subcategoriaCompra } from './subcategoriaCompra.reducer'
import { tipoAjustePontuacao } from './tipoAjustePontuacao.reducer'
import { tipoUsuario } from './tipoUsuario.reducer'
import { totvsInsumo } from './totvsInsumo.reducer'
import { totvsInsumoUnidade } from './totvsInsumoUnidade.reducer'
import { totvsInsumoConversao } from './totvsInsumoConversao.reducer'
import { totvsInsumoPrecoReferencia } from './totvsInsumoPrecoReferencia.reducer'
import { totvsVenda } from './totvsVenda.reducer'
import { troca } from './troca.reducer'
import { unidade } from './unidade.reducer'
import { usuario } from './usuario.reducer'
import { usuarioLoja } from './usuarioLoja.reducer'

import { combineReducers } from 'redux'

import { reducer as formReducer } from 'redux-form'

const appReducer = combineReducers({
  authentication,
  bling,
  banner,
  campanha,
  categoriaCompra,
  categoriaInsumo,
  certificado,
  checklist,
  checklistTotvsInsumoUnidade,
  checklistOperacao,
  checklistOperacaoItem,
  cidade,
  cliente,
  compra,
  cupomProduto,
  dashboard,
  dashboardCompra,
  estado,
  etiqueta,
  fidelizacao,
  fornecedor,
  fornecedorFilial,
  fornecedorInfo,
  grupo,
  grupoFornecedores,
  listaPreco,
  log,
  loja,
  mensagem,
  meta,
  notificacao,
  notificacaoFiltro,
  notificacaoUsuario,
  parametro,
  pedidoCompra,
  permissao,
  permissaoTipoUsuario,
  permissaoUsuario,
  pontuacao,
  produto,
  produtoSelect,
  relatorio,
  respostaChecklistOperacao,
  retrospectiva,
  sorteio,
  subcategoriaCompra,
  tipoAjustePontuacao,
  tipoUsuario,
  totvsInsumo,
  totvsInsumoUnidade,
  totvsInsumoConversao,
  totvsInsumoPrecoReferencia,
  totvsVenda,
  troca,
  unidade,
  usuario,
  usuarioLoja,
  form: formReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    const { routing } = state
    state = { routing }
  }
  return appReducer(state, action)
}
export default rootReducer
