const initialState = {
  isLoading: false,
  totalRegistros: 0,
  fornecedor: [],
  id: 0,
  nome: '',
  email: '',
  integracao: false,
  integracaoArquivo: false,
  tipoIntegracaoAPI: '',
  versaoIntegracaoAPI: '',
  chaveIntegracaoAPI: '',
  idGrupoFornecedores: 0,
  excluido: false,
  fornecedoresLojas: [],
  todasLojas: false,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function fornecedor(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_FORNECEDOR':
      return {
        ...state,
        fornecedor: action.fornecedor,
        totalRegistros: action.totalRegistros
      }
    case 'FORNECEDOR_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.fornecedor.id,
        nome: action.fornecedor.nome,
        email: action.fornecedor.email,
        integracao: action.fornecedor.integracao,
        integracaoArquivo: action.fornecedor.integracaoArquivo,
        tipoIntegracaoAPI: action.fornecedor.tipoIntegracaoAPI,
        versaoIntegracaoAPI: action.fornecedor.versaoIntegracaoAPI,
        idGrupoFornecedores: action.idGrupoFornecedores,
        excluido: action.fornecedor.excluido,
        fornecedoresLojas: action.fornecedor.fornecedoresLojas,
        fornecedoresFiliais: action.fornecedor.fornecedoresFiliais,
        todasLojas: action.fornecedor.todasLojas
      }
    case 'FORNECEDOR_UPDATED':
      return state
    case 'FORNECEDOR_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'FORNECEDOR_CLEAR_ALL':
      return {
        ...state,
        fornecedor: []
      }
    case 'FORNECEDOR_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'FORNECEDOR_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'FORNECEDOR_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
