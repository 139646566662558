import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, produtoAction } from '../../_actions'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Card,
  CardContent,
  CircularProgress,
  Button,
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import Skeleton from "@material-ui/lab/Skeleton"
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print'
import { Page } from 'components'
import moment from 'moment'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  gridButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    textAlign: 'right'
  }
})

const RelatorioTroca = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { relatorio } = props.relatorio
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio

  const [dataInicial, setDataInicial] = React.useState(undefined)
  const [dataFinal, setDataFinal] = React.useState(undefined)
  const [nome, setNome] = React.useState('')
  const [codigoProduto, setCodigoProduto] = React.useState(0)

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false)

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  //const produto = useSelector(
  //  (state) => state.produtoSelect.produtoSelect
  //)

  const { produto } = useSelector(
    (state) => state.produtoSelect
  )

  const options = produto.map(u => ({ id: u.value, nome: u.text }));

  useEffect(() => {
    const dataIni = moment().subtract(1, "months")
    const dataFi = moment();

    setDataInicial(moment(dataIni).format("YYYY-MM-DD"));
    setDataFinal(moment(dataFi).format("YYYY-MM-DD"));

    dispatch(relatorioAction.clear());
  }, []);

  useEffect(() => {

    if (idLoja) {
      dispatch(produtoAction.getProdutoByLojaSelect(idLoja));
    }

    setNome("")
  }, [idLoja]);

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  const handleChangeAutoComplete = (obj) => {
    if (obj != null) {
      let selectedProduto = produto.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        produtoAction.changeDetailsProduto(
          selectedProduto[0]
        )
      )
    } else {

    }
    setOpen(false)
  }

  const handleSubmit = (event) => {

    if (dataInicial != undefined && dataFinal != undefined) {

      if (dataFinal >= dataInicial) {
        let payload = {
          IdLoja: idLoja ? idLoja : 0,
          DataInicial: moment(dataInicial).format("YYYY-MM-DDTHH:mm:ssZ"),
          DataFinal: moment(dataFinal).format("YYYY-MM-DDTHH:mm:ssZ"),
          Nome: nome,
          CodigoProduto: 0 //idLoja != 0 ? value ? value.id : 0 : 0
        };

        dispatch(relatorioAction.getRelatorioTroca(payload, true));
      }
      else {
        toast.error('Data final deve ser maior que a Data inicial!')
      }
    } else {
      dispatch(relatorioAction.clear());
    }
  };

  const handleChangeDataInicial = (e) => {
    setDataInicial(e.target.value)
  }

  const handleChangeDataFinal = (e) => {
    setDataFinal(e.target.value)
  }

  const handleChangeNome = (e) => {
    setNome(e.target.value)
  }

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [props.relatorio.isLoading])

  const handlePrint = () => {
    window.open('/relatorioTroca', '_blank');
  }

  function getTotal() {
    if (relatorio && relatorio.length > 0) {

      let total = relatorio.length;

      return total;

    } else {
      return 0;
    }
  }

  return (
    <Page className={classes.root} title="Relatório - Trocas por Período">
      <AccessControl
        rule={'relatorios.admin'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioTrocas"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Trocas por Período'}
                    />
                    <Grid container spacing={2}>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Início *"
                          type="date"
                          value={dataInicial}
                          onChange={handleChangeDataInicial}
                          id="dataInicial"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Final *"
                          type="date"
                          value={dataFinal}
                          onChange={handleChangeDataFinal}
                          id="dataFinal"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      {/*{idLoja ?*/}
                      {/*  <Grid item xl={6} lg={6} md={6} xs={12}>*/}
                      {/*    <Autocomplete*/}
                      {/*      value={value}*/}
                      {/*      onChange={(event, newValue) => {*/}
                      {/*        setValue(newValue);*/}
                      {/*      }}*/}
                      {/*      inputValue={inputValue}*/}
                      {/*      onInputChange={(event, newInputValue) => {*/}
                      {/*        setInputValue(newInputValue);*/}
                      {/*      }}*/}
                      {/*      id="controllable-states-demo"*/}
                      {/*      options={options}*/}
                      {/*      defaultValue={null}*/}
                      {/*      getOptionLabel={option => option.nome}*/}
                      {/*      renderInput={(params) => <TextField {...params} label="SELECIONE UM PRODUTO... " variant="outlined" fullWidth />}*/}
                      {/*      style={{ width: "312px" }}*/}
                      {/*      fullWidth*/}
                      {/*    />*/}
                      {/*  </Grid>*/}
                      {/*  :*/}
                      <Grid item xl={6} lg={6} md={6} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Produto"
                          value={nome}
                          onChange={handleChangeNome}
                          id="nome"
                        />
                      </Grid>
                      {/*}*/}
                      <Grid item xl={6} lg={6} md={6} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </Button>
                        </Grid>
                        <Grid>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handlePrint()}
                            variant="outlined"
                            startIcon={<PrintIcon />}
                          >
                            Imprimir
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Loja</TableCell>
                        <TableCell>Voucher</TableCell>
                        <TableCell>Produto</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Nome do Cliente</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {undefined !== relatorio && relatorio.length
                        ? relatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {moment(n.data).format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.loja || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.voucher || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.descricaoProduto || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.status && n.status == true || n.status == null ? 'PROCESSADO' : 'PENDENTE'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.nomeCliente || ''}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (dataInicial != undefined && dataFinal != undefined ?
                          (isLoading ?
                            Array.apply(null, { length: 6 }).map((e, i) => (
                              <TableRow hover>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow>
                              <TableCell rowSpan={6} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                            </TableRow>
                          )
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={7} align="center">Informe uma Data de Início e uma Data de Final</TableCell>
                          </TableRow>
                        )
                      }
                      {undefined !== relatorio && relatorio.length
                        ?
                        <TableRow>
                          <TableCell rowSpan={4} colSpan={5}><b>Total</b></TableCell>
                          <TableCell rowSpan={4}><b>{getTotal()}</b></TableCell>
                        </TableRow>
                        :
                        null
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
RelatorioTroca.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedRelatorioTrocaPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioTroca))
)
export { connectedRelatorioTrocaPage as RelatorioTroca }
