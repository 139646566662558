import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core'
import { options } from './options'
import { useSelector } from 'react-redux'
import { dashboardAction } from '../../../../_actions'
import { Skeleton } from '@material-ui/lab'
import { ClientesInfoModal } from './clientesInfoModal.component';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const GraficoClientesPontosAcumuladosChartCard = (props) => {
  const dispatch = useDispatch()

  const [valor1, setValor1] = useState('');
  const [valor2, setValor2] = useState('');

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const { className, idLoja, dataInicio, dataFim, ...rest } = props
  const graficoClientesPontosAcumulados = useSelector((state) => (state.dashboard ? state.dashboard.graficoClientesPontosAcumulados : undefined))
  const { isLoadingPontosAcumulados } = useSelector((state) => state.dashboard)
  const classes = useStyles()

  useEffect(() => {
    let filterModel = {
      IdLoja: idLoja,
    };

    dispatch(dashboardAction.getDashboardLojaClientesPontosAcumulados(filterModel, true));
  }, [idLoja, dataInicio, dataFim]);

  const generateData = () => {
    if (graficoClientesPontosAcumulados) {
      const data = {
        labels: graficoClientesPontosAcumulados.options,
        datasets: [
          {
            label: 'Total de Clientes',
            yAxisID: 'A',
            backgroundColor: '#4CAF50',
            data: graficoClientesPontosAcumulados.series[0]
          }
        ]
      }
      return data
    } else {
      return []
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title="CLIENTES DE ACORDO COM PONTOS ACUMULADOS"
      />
      <Divider />
      <CardContent className={classes.chartContainer}>
        {isLoadingPontosAcumulados ? (
          <Skeleton animation="wave" style={{ marginTop: -140 }} height={600} />
        ) : (
          <Bar
            data={generateData}
            options={options}
            getElementAtEvent={(elements, event) => {
              if (event.type === "click" && elements.length) {
                const i = elements[0]._index;

                if (elements[0]._xScale) {
                  const valores = elements[0]._xScale.ticks[i];

                  if (valores && valores.length) {
                    if (valores.length == 1)
                    {
                      setValor1(valores);
                      setValor2(valores);
                    }
                    else if (valores.includes("Acima de"))
                    {
                      const valor = valores.split("Acima de", 2)[1].trim();

                      setValor1(valor);
                      setValor2(valor);
                    }
                    else
                    {
                      var arrayValoresIntervalo = valores.split("a", 2);

                      setValor1(arrayValoresIntervalo && arrayValoresIntervalo[0] != null ? arrayValoresIntervalo[0].trim() : '');
                      setValor2(arrayValoresIntervalo && arrayValoresIntervalo[1] != null ? arrayValoresIntervalo[1].trim() : '');
                    }
                  }

                  toggle();
                }
              }
            }}
          />
        )}
      </CardContent>
      <Divider />
      {
        (modal ?
          <ClientesInfoModal toggle={toggle} modal={modal} valor1={valor1} valor2={valor2} props={props} />
          :
          null
        )
      }
    </Card>
  )
}

GraficoClientesPontosAcumuladosChartCard.propTypes = {
  className: PropTypes.string
}

export default GraficoClientesPontosAcumuladosChartCard
