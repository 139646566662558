import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { etiquetaAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@material-ui/core'
import { KeyboardDatePicker } from "@material-ui/pickers";
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck'
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Skeleton } from "@material-ui/lab";
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce'
import moment from 'moment'
import { useConfirm } from 'material-ui-confirm'
import { CardEtiqueta } from './components'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  gridCards: {
    justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  card: {
    marginBottom: theme.spacing(2),
    minHeight: 170
  }
})

const Etiqueta = (props) => {
  const dispatch = useDispatch()
  const { classes } = props
  const { match: { params } } = props;

  const { etiqueta, isLoading } = useSelector(
    (state) => state.etiqueta
  )

  const { className, ...rest } = props
  const { page, limit, term, direction, order } = useSelector(state => state.etiqueta);

  const [debounceTerm] = useDebounce(term, 1000);
  const confirm = useConfirm()

  const [openDialog, setOpenDialog] = useState(false);
  const [hasFilter, setHasFilter] = useState(false)

  const [data, setData] = useState(null)
  const [dataValue, setDataValue] = useState(null)

  const [dataValidade, setDataValidade] = useState(null)
  const [dataValidadeValue, setDataValidadeValue] = useState(null)

  const [selectGroup, setSelectGroup] = useState(false);
  const [checkedItems, setCheckedItems] = useState([])

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const handleChangeSelectGroup = () => {
    setSelectGroup(!selectGroup)
  }

  const handleDeleteGroup = () => {
    if (!checkedItems.length) {
      toast.warning('Selecione as etiquetas que deseja excluir!')
      return
    }

    confirm({
      title: 'Você deseja excluir as etiquetas selecionadas?',
      description: 'Essa operação é irreversível',
      disabled: isLoading,
      confirmationText: isLoading ? 'Excluindo etiquetas...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(etiquetaAction.deleteEtiquetasGroup(checkedItems))

      setSelectGroup(false)
      setCheckedItems([])
    })
  }

  const handleCheck = (checked, itemId) => {
    let newCheckedItems = [...checkedItems];

    if (checked && !checkedItems.includes(itemId)) {
      newCheckedItems.push(itemId);
    } else {
      newCheckedItems = newCheckedItems.filter(item => item !== itemId);
    }

    setCheckedItems(newCheckedItems);
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }

    dispatch(etiquetaAction.onChangeProps('term', fakeEvent))
  }

  const handleChangeData = (date) => {
    const formatDate = date ? moment(date).format('yyyy-MM-DD') : null

    setData(formatDate)
    setDataValue(date)
  };

  const handleChangeDataValidade = (date) => {
    const formatDate = date ? moment(date).format('yyyy-MM-DD') : null

    setDataValidade(formatDate)
    setDataValidadeValue(date)
  };

  const handleChangeOrder = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }

    dispatch(etiquetaAction.onChangeProps('order', fakeEvent))
  }

  const handleChangeDirection = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }

    dispatch(etiquetaAction.onChangeProps('direction', fakeEvent))
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClearFilters = () => {
    setData(null)
    setDataValue(null)
    setDataValidade(null)
    setDataValidadeValue(null)

    handleChangeOrder('dataValidade')
    handleChangeDirection('desc')

    setHasFilter(false)
  }

  const handleApplyFilters = () => {
    const containsFilter = data || dataValidade

    setHasFilter(containsFilter)

    fetchData()

    setOpenDialog(false)
  }

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'dataValidade',
      Direction: direction || 'desc',
      Data: data || '',
      DataValidade: dataValidade || '',
      IdLoja: (idLoja && idLoja !== '') ? idLoja : 0
    };

    dispatch(etiquetaAction.getEtiqueta(filterModel, true));
  };

  useEffect(() => {
    handleChangeOrder('dataValidade')
    handleChangeDirection('desc')

    dispatch(etiquetaAction.getEtiqueta(idLoja))
  }, []);

  useEffect(() => {
    fetchData();
  }, [debounceTerm, idLoja]);

  return (
    <Page className={classes.root} title="Etiquetas">
      <AccessControl
        rule={'etiquetas'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div {...rest} className={clsx(classes.root, className)}>

            <div {...rest} className={clsx(classes.root, className)}>
              <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Typography component="h2" gutterBottom variant="overline">
                    GERÊNCIA DE ETIQUETAS DE OPERAÇÃO
                  </Typography>
                  <Typography component="h1" variant="h3">
                    Lista de Etiquetas de Operação
                  </Typography>
                </Grid>
                {!selectGroup ?
                  <Button
                    onClick={handleChangeSelectGroup}
                    variant="contained"
                    color={"primary"}
                    startIcon={<LibraryAddCheckIcon />}
                  >
                    Seleção conjunta
                  </Button>
                  :
                  <Grid item>
                    <Button
                      variant="contained"
                      color={"primary"}
                      startIcon={<DeleteIcon />}
                      onClick={handleDeleteGroup}
                    >
                      Excluir
                    </Button>
                    <IconButton onClick={handleChangeSelectGroup} color="primary">
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                }
              </Grid>
              <br></br>
              <Typography variant="body2" align="left" gutterBottom>
                <b>{'Total de etiquetas encontradas: ' + etiqueta.length}</b>
              </Typography>
            </div>

            <div {...rest} className={clsx(classes.root, className)}>
              <Grid container spacing={2}>
                <Grid item xl={5} lg={5} md={5} xs={5}>
                  <Input
                    id="term"
                    label="term"
                    placeholder="Filtro"
                    value={term}
                    onChange={(event) => handleChangeTerm(event.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xl={1} lg={1} md={1} xs={1}>
                  <Tooltip title="Filtros Avançados">
                    <IconButton className={classes.margin} size="small" onClick={handleOpenDialog}>
                      <Badge color="primary" variant="dot" invisible={!hasFilter}>
                        <FilterListIcon fontSize="inherit" />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>

            <Grid alignItems="center" container justifyContent="space-between" spacing={3}>
              <Grid container className={classes.gridCards} spacing={2}>
                {!isLoading ?
                  undefined !== etiqueta && etiqueta.length
                    ? etiqueta.map((n) => {
                      return (
                        <Grid container lg={4} sm={6} xl={3} xs={12} spacing={2} key={n.id}>
                          <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <CardEtiqueta
                              variant="elevation"
                              className={classes.card}
                              etiqueta={n}
                              selectGroup={selectGroup}
                              handleCheck={handleCheck}
                              isChecked={checkedItems.some((x) => x == n.id)}
                            />
                          </Grid>
                        </Grid>
                      )
                    })
                    : null
                  :
                  Array.from(new Array(3)).map((_, index) => (
                    <Grid item lg={4} sm={6} xl={3} xs={12}>
                      <Skeleton className={classes.card} animation="wave" height={200} marginTop={-140} />
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </div>
        )
        }
        no={() => <AccessDenied />}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth aria-modal>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Typography variant="h4">Filtros Avançados</Typography>

            <IconButton size="small" onClick={handleCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="data"
                label="Data de Produção"
                fullWidth
                value={dataValue}
                onChange={handleChangeData}
                invalidDateMessage="Formato de data inválido"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="data"
                label="Data de Validade"
                fullWidth
                value={dataValidadeValue}
                onChange={handleChangeDataValidade}
                invalidDateMessage="Formato de data inválido"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <Select
                id="order"
                value={order}
                onChange={(event) => handleChangeOrder(event.target.value)}
                fullWidth
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em>Ordernar por</em>
                </MenuItem>

                <MenuItem key={0} value={'data'}>
                  {'Produção'}
                </MenuItem>

                <MenuItem key={1} value={'dataValidade'}>
                  {'Validade'}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <Select
                id="direction"
                value={direction}
                onChange={(event) => handleChangeDirection(event.target.value)}
                fullWidth
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em>Direção</em>
                </MenuItem>

                <MenuItem key={0} value={'asc'}>
                  {'Ascendente'}
                </MenuItem>

                <MenuItem key={1} value={'desc'}>
                  {'Descendente'}
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClearFilters} color="primary">
            Limpar
          </Button>
          <Button
            onClick={handleApplyFilters}
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={<FilterListIcon />}
            endIcon={isLoading && <CircularProgress size={24} />}
          >
            Aplicar Filtros
          </Button>
        </DialogActions>
      </Dialog>
    </Page >
  )
}

Etiqueta.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    checklistOperacao: state.checklistOperacao
  }
}

const connectedEtiquetaPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Etiqueta))
)

export { connectedEtiquetaPage as Etiqueta }
