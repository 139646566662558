const initialState = {
  isLoading: false,
  totalRegistros: 0,
  grupoFornecedores: [],
  id: 0,
  nome: '',
  fornecedores: [],
  excluido: false,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function grupoFornecedores(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_GRUPO_FORNECEDORES':
      return {
        ...state,
        grupoFornecedores: action.grupoFornecedores,
        totalRegistros: action.totalRegistros
      }
    case 'GRUPO_FORNECEDORES_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.grupoFornecedores.id,
        nome: action.grupoFornecedores.nome,
        fornecedores: action.grupoFornecedores.fornecedores,
        excluido: action.grupoFornecedores.excluido,
      }
    case 'GRUPO_FORNECEDORES_UPDATED':
      return state
    case 'GRUPO_FORNECEDORES_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'GRUPO_FORNECEDORES_CLEAR_ALL':
      return {
        ...state,
        grupoFornecedores: []
      }
    case 'GRUPO_FORNECEDORES_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'GRUPO_FORNECEDORES_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'GRUPO_FORNECEDORES_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
