import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useConfirm } from 'material-ui-confirm'
import { withStyles } from '@material-ui/core/styles'
import {
  Backdrop,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  Input,
  MenuItem,
  Typography
} from '@material-ui/core'
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import { bannerAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import SaveIcon from '@material-ui/icons/Save'
import { Page, ImageUploader, EditorHeader } from 'components'
import { uploadImage } from '_services'
import { errorsUploadImage, getInitials } from 'utils'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const BannerEdit = (props) => {
  const dispatch = useDispatch()
  const { classes, banner, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [images, setImages] = useState([])
  const [imagem, setImagem] = useState('')
  const [open, setOpen] = useState(false)

  const [imagesBanner, setImagesBanner] = useState([])
  const [imagemBanner, setImagemBanner] = useState('')
  const [openBanner, setOpenBanner] = useState(false)

  const confirm = useConfirm()

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    if (params.id) {
      dispatch(bannerAction.getBannerById(params.id))
    } else {
      dispatch(bannerAction.clear())
    }

    setImagem(null)
    setImagemBanner(null)

  }, []);

  const removeImage = (field) => {

    console.log(field);

    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {

      //let payload = {
      //  ...banner,
      //  caminhoImagem: null
      //}
      //dispatch(
      //  bannerAction.editBannerInfo(banner.id, payload, false)
      //)

      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(bannerAction.onChangeProps(field, event2))

      if (field == 'caminhoImagem') {
        setImagem(null)
      }
      else if (field == 'caminhoImagemBanner') {
        setImagemBanner(null)
      }
      

    })
  }

  const onChangeImage = (imageList) => {
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...banner,
          caminhoImagem: response.toString()
        }

        if (banner.id !== 0) {
          dispatch(
            bannerAction.editBannerInfo(banner.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(bannerAction.onChangeProps('caminhoImagem', event2))

          setImagem(response)
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  const onChangeImageBanner = (imageList) => {
    // data for submit
    setOpenBanner(true)
    setImagesBanner(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...banner,
          caminhoImagemBanner: response.toString()
        }

        if (banner.id !== 0) {
          dispatch(
            bannerAction.editBannerInfo(banner.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(bannerAction.onChangeProps('caminhoImagemBanner', event2))

          setImagemBanner(response)
          setOpenBanner(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  useEffect(() => {

  }, [ready, props.banner.isLoading])

  useEffect(() => {
    setImagem(banner.caminhoImagem)
  }, [banner.caminhoImagem, open]);

  useEffect(() => {
    //  forceUpdate(n => !n);
  }, [open, imagem]);

  useEffect(() => {
    setImagemBanner(banner.caminhoImagemBanner)
  }, [banner.caminhoImagemBanner, openBanner]);

  useEffect(() => {
    //  forceUpdate(n => !n);
  }, [openBanner, imagemBanner]);

  const handleChange = (prop) => (event) => {
    dispatch(bannerAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {
    let payload = {
      id: params.id,
      nome: props.banner.nome,
      descricao: props.banner.descricao,
      dataHoraInicio: moment(props.banner.dataHoraInicio).toISOString(),
      dataHoraTermino: moment(props.banner.dataHoraTermino).toISOString(),
      caminhoImagem: props.banner.caminhoImagem,
      caminhoImagemBanner: props.banner.caminhoImagemBanner,
      acao: props.banner.acao,
      chave: props.banner.chave,
    }

    if (params.id) {
      dispatch(bannerAction.editBannerInfo(params.id, payload, true))
    } else {
      dispatch(bannerAction.createBanner(payload))
    }
  };

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Novo Banner'}</b>
      </Typography>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Banners - Editar Banner'
          : 'Gerência de Banners - Novo Banner'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={4} md={6} xl={3} xs={12}>

          <Grid item md={12} xs={12}>
            <Backdrop className={classes.backdrop} open={open}>
              <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="textPrimary">
                    Enviando imagem...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
            <ImageUploader
              images={images}
              onChangeImage={onChangeImage}
              onError={errorsUploadImage}
              acceptType={['jpeg', 'jpg']}
              maxFileSize={500000}
              resolutionWidth={640}
              resolutionHeight={360}
              resolutionType={"less"}
              removeImage={() => removeImage('caminhoImagem')}
              props={banner}
              imagem={imagem}
              getInitials={getInitials}
              classes={classes}
              rest={rest}
              className={className}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ marginTop: 30 }}>
            <Backdrop className={classes.backdrop} open={openBanner}>
              <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="textPrimary">
                    Enviando imagem...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
            <ImageUploader
              images={imagesBanner}
              onChangeImage={onChangeImageBanner}
              onError={errorsUploadImage}
              acceptType={['jpeg', 'jpg']}
              maxFileSize={1000000}
              resolutionWidth={1200}
              resolutionHeight={900}
              isBanner
              resolutionType={"less"}
              removeImage={() => removeImage('caminhoImagemBanner')}
              props={banner}
              imagem={imagemBanner}
              getInitials={getInitials}
              classes={classes}
              rest={rest}
              className={className}
            />
          </Grid>

        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formBanner"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Banners"
                  title={(
                    params.id ?
                      <EditorHeader title='Editar Banner' tabela='Banner' chave={params.id} props={props} />
                    :
                      <InsertText />
                  )}
                />
                <Divider />
                <CardContent >
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onChange={handleChange('nome')}
                        value={props.banner.nome || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="descricao"
                        label="Descrição *"
                        margin="normal"
                        onChange={handleChange('descricao')}
                        value={props.banner.descricao || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        type="datetime-local"
                        id="dataHoraInicio"
                        InputLabelProps={{ shrink: true }}
                        label="Data/Hora Início *"
                        margin="normal"
                        onChange={handleChange('dataHoraInicio')}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        value={props.banner.dataHoraInicio ? moment(props.banner.dataHoraInicio).format("yyyy-MM-DDTHH:mm") : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        type="datetime-local"
                        InputLabelProps={{ shrink: true }}
                        id="dataHoraTermino"
                        label="Data/Hora Término *"
                        onChange={handleChange('dataHoraTermino')}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        margin="normal"
                        value={props.banner.dataHoraTermino ? moment(props.banner.dataHoraTermino).format("yyyy-MM-DDTHH:mm") : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        label="Ação"
                        labelId="acao"
                        errorMessages={['Campo obrigatório!']}
                        value={props.banner.acao || 'Home'}
                        onChange={handleChange('acao')}
                        name="acao"
                        fullWidth
                        inputProps={{
                          name: "Acao",
                          id: "acao"
                        }}
                        input={<Input id="acao" />}
                        margin="normal"
                        variant="outlined"
                        id="acao"
                      >
                        <MenuItem value={'DetalheNotificacao'}>Conteúdo do Banner</MenuItem>
                        <MenuItem value={'Home'}>Tela inicial</MenuItem>
                        <MenuItem value={'OpenLink'}>Abrir link externo</MenuItem>
                        <MenuItem value={'Trocas'}>Tela de trocas</MenuItem>
                        <MenuItem value={'LojasMenu'}>Lojas</MenuItem>
                        <MenuItem value={'Retrospectiva'}>Retrospectiva</MenuItem>
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={8} md={8} xl={8} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="chave"
                        label="Chave"
                        margin="normal"
                        type={props.banner.acao == 'DetalheNotificacao' ? 'number' : 'text'}
                        onChange={handleChange('chave')}
                        value={props.banner.chave}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/Banners')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.banner.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.banner.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.banner.isLoading ? 'Atualizando banner...' : 'Atualizar') : (props.banner.isLoading ? 'Salvando banner...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

BannerEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedBannerEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(BannerEdit))
)
export { connectedBannerEditPage as BannerEdit }
