import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { history } from '../utils'
import clsx from 'clsx'
import { AccessControl } from '../components/Utils/AccessControl'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { compraAction } from '../_actions'
import { useDebounce } from 'use-debounce';

const useStyles = makeStyles(() => ({
  root: {}
}))

const options = ['Novo', 'Atualizar compras'];

const TitleContentCompra = (props) => {
  const dispatch = useDispatch()

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [order] = useState('dataHora');
  const [direction] = useState('desc');
  const [debounceTerm] = useDebounce(term, 1000);

  const { idLoja } = useSelector(state => state.usuarioLoja)
  const { isLoading } = useSelector(state => state.compra)

  const { className, ...rest } = props
  const classes = useStyles()

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleExportar = () => {
    let filterModel;

    const fetchData = (page, limit, term) => {
      filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdLoja: (idLoja && idLoja !== '' ? idLoja : 0)
      };

      dispatch(compraAction.getCompraArquivo(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

    dispatch(compraAction.getCompra(filterModel, true));
  }

  const handleClick = () => {
    history.push('compra');
  };

  const handleMenuItemClick = (event, index) => {

    setOpen(false);

    if (index == 1) {
      dispatch(compraAction.refreshComprasProdutos())
    }

  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {props.subTitle}
          </Typography>
          <Typography component="h1" variant="h3">
            {props.title}
          </Typography>
        </Grid>
        <AccessControl
          rule={props.rule} //permissão necessária para acessar conteúdo
          yes={() => (
            <Grid item style={{
              display: 'flex',
              alignItems: 'right',
              flexDirection: 'row',
              textAlgin: 'right',
              justifyContent: 'right',
              spacing: 5,
              gap: 10
            }}>
              <Button
                variant="contained"
                color="default"
                onClick={() => handleExportar()}
                disabled={isLoading}
              >
                EXPORTAR
              </Button>

              <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                <Button onClick={handleClick}>{options[0]}</Button>
                <Button
                  color="primary"
                  size="small"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              selected={index === 0}
                              disabled={index === 0}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          )}
        />
      </Grid>
      <br></br>
      {props.length !== undefined && props.length !== 0 ? (
        <Grid item>
          <Typography variant="body2" align="left" gutterBottom>
            Total de dados encontrados: <b>{props.length}</b>
          </Typography>
        </Grid>
      ) : (
        <Typography variant="body2" align="left" gutterBottom>
          <b>{'Nenhum dado encontrado.'}</b>
        </Typography>
      )}
    </div>
  )
}

TitleContentCompra.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  rule: PropTypes.string
}
export default TitleContentCompra
