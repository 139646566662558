import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Input,
  Grid,
  Table,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Button
} from '@material-ui/core'
import { history } from '../../../../utils'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Skeleton } from "@material-ui/lab";
import { clienteAction } from '../../../../_actions'
import { useDebounce } from 'use-debounce';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const ClientesInfoModal = ({ modal, toggle, valor1, valor2 }, props) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 2000);
  const [order, setOrder] = useState('nomeCliente');
  const [direction, setDirection] = useState('asc');

  const { cliente, totalRegistros, isLoading } = useSelector(
    (state) => state.cliente
  )

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  useEffect(() => {
    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdLoja: idLoja,
        Valor1: valor1,
        Valor2: valor2
      };

      dispatch(clienteAction.clear())
      dispatch(clienteAction.getClientesByPontosAcumulados(filterModel, true));
    }

    fetchData(page, limit, debounceTerm, order, direction);
  }, [page, limit, debounceTerm, order, direction]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  const getTitle = () => {
    const titleBase = 'Clientes que possuem pontuação atual';

    if (valor1 !== valor2) {
      return `${titleBase} entre ${valor1} e ${valor2}`;
    }

    return valor1 === 0
      ? `${titleBase} igual a ${valor1}`
      : `${titleBase} acima de ${valor1}`;
  }

  return (
    <Dialog
      open={modal}
      onClose={toggle}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="form-dialog-title">
        {getTitle()}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Pesquisar por cliente"
                value={term}
                onChange={handleChange('term')}
              />
            </Grid>
            <br />
            <Grid item lg={12} md={12} xl={12} xs={12} style={{ justifyContent: 'center', alignContent: 'center' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>E-mail</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(undefined !== cliente && cliente.length
                      ? cliente.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" style={{ cursor: 'pointer' }} scope="row" onClick={() => history.push(window.open(`/cliente/${n.id}`))}>
                              {n.nomeCliente ? n.nomeCliente.toUpperCase() : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {n.email ? n.email : ''}
                            </TableCell>
                          </TableRow>
                        )
                      })
                      :
                      (
                        isLoading ?
                          Array.apply(null, { length: 6 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                          :
                          <TableRow>
                            <TableCell rowSpan={3} colSpan={2} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={totalRegistros}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeLimit}
              />
            </Grid>
          </>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          style={{ padding: 5 }}
          spacing={2}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Button onClick={toggle} variant="outlined" color="primary">
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
