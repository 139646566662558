import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const etiquetaAction = {
  getEtiqueta,
  getEtiquetaById,
  addEtiqueta,
  onChangeProps,
  editEtiquetasDetails,
  createEtiqueta,
  deleteEtiquetaById,
  deleteEtiquetasGroup,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getEtiqueta(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'etiquetas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeEtiquetasList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getEtiquetaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'etiquetas/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editEtiquetasDetails(response.data))
    })
  }
}

function createEtiqueta(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'etiquetas/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createEtiquetaInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/etiquetas')
        } else {
          toast.err('Oops! Erro ao cadastrar etiqueta! 😥')
        }
      }).catch((err) => {
        // console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar etiqueta! 😥')
        return err.response;
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteEtiquetaById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'etiquetas/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteEtiquetasDetails())
      dispatch(getEtiqueta(filterModel))
      dispatch(notIsLoading())
      history.push('/etiquetas')
    })
  }
}

function deleteEtiquetasGroup(etiquetasId) {
  return (dispatch) => {
    let apiEndpoint = `etiquetas?ids=${etiquetasId}`

    dispatch(isLoading())

    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteEtiquetasDetails())
      dispatch(getEtiqueta(filterModel))
      dispatch(notIsLoading())
      history.push('/etiquetas')
    }).finally(() => {
      dispatch(notIsLoading())
    })
  }
}

export function addEtiqueta(payload) {
  return [{ type: 'ADD_ETIQUETA', etiqueta: payload }, clear()]
}

export function changeEtiquetasList(etiqueta, totalRows) {
  return {
    type: 'FETCHED_ALL_ETIQUETA',
    etiqueta: etiqueta,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'ETIQUETA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'ETIQUETA_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'ETIQUETA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editEtiquetasDetails(etiqueta) {
  return [
    { type: 'ETIQUETA_DETAIL', etiqueta },
  ]
}

export function createEtiquetaInfo() {
  return {
    type: 'ETIQUETA_CREATED_SUCCESSFULLY'
  }
}

export function deleteEtiquetasDetails() {
  return {
    type: 'DELETED_ETIQUETA_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'ETIQUETA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'ETIQUETA_NOTISLOADING'
  }
}
