import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const grupoFornecedoresAction = {
  getGrupoFornecedores,
  addGrupoFornecedores,
  changeDetailsGrupoFornecedores,
  getGrupoFornecedoresSelectLoja,
  getGrupoFornecedoresById,
  onChangeProps,
  editGrupoFornecedoresInfo,
  editGrupoFornecedoresDetails,
  createGrupoFornecedores,
  deleteGrupoFornecedoresById,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getGrupoFornecedores(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'gruposFornecedores' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeGrupoFornecedoresList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createGrupoFornecedores(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'gruposFornecedores/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createGrupoFornecedoresInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())

          history.push(`/grupoFornecedores/${ret.key}`)
          dispatch(getGrupoFornecedoresById(ret.key))
        } else {
          toast.err('Oops! Erro ao cadastrar grupo! 😥')
        }
      }).catch((err) => {
        // console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar grupo! 😥')
        return err.response;
      })
  }
}

function changeDetailsGrupoFornecedores(grupoFornecedores) {
  return (dispatch) => {
    if (grupoFornecedores) {
      dispatch(onChangeDetailsGrupoFornecedores(grupoFornecedores))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsGrupoFornecedores(grupoFornecedores) {
  return [
    {
      type: 'CHANGE_DETAILS_GRUPO_FORNECEDORES',
      id: grupoFornecedores.id,
      nome: grupoFornecedores.nome
    }
  ]
}

function getGrupoFornecedoresSelectLoja(id) {
  return (dispatch) => {
    let apiEndpoint = 'gruposFornecedores/select/loja/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeGrupoFornecedoresList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getGrupoFornecedoresById(id) {
  return (dispatch) => {
    let apiEndpoint = 'gruposFornecedores/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editGrupoFornecedoresDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editGrupoFornecedoresInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'gruposFornecedores/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedGrupoFornecedoresInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/gruposFornecedores')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar grupo! 😥')
        //  console.log(err.response);
        return err.response;
      }
      )
  }
}

function deleteGrupoFornecedoresById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'gruposFornecedores/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteGrupoFornecedoresDetails())
      dispatch(getGrupoFornecedores(filterModel))
      dispatch(notIsLoading())
      history.push('/gruposFornecedores')
    })
  }
}

export function addGrupoFornecedores(payload) {
  return [{ type: 'ADD_GRUPO_FORNECEDORES', grupoFornecedores: payload }, clear()]
}

export function changeGrupoFornecedoresList(grupoFornecedores, totalRows) {
  return {
    type: 'FETCHED_ALL_GRUPO_FORNECEDORES',
    grupoFornecedores: grupoFornecedores,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'GRUPO_FORNECEDORES_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'GRUPO_FORNECEDORES_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'GRUPO_FORNECEDORES_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editGrupoFornecedoresDetails(grupoFornecedores) {
  return [
    { type: 'GRUPO_FORNECEDORES_DETAIL', grupoFornecedores },
  ]
}

export function updatedGrupoFornecedoresInfo() {
  return {
    type: 'GRUPO_FORNECEDORES_UPDATED'
  }
}

export function createGrupoFornecedoresInfo() {
  return {
    type: 'GRUPO_FORNECEDORES_CREATED_SUCCESSFULLY'
  }
}

export function deleteGrupoFornecedoresDetails() {
  return {
    type: 'DELETED_GRUPO_FORNECEDORES_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'GRUPO_FORNECEDORES_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'GRUPO_FORNECEDORES_NOTISLOADING'
  }
}
