const initialState = {
  isLoading: false,
  totalRegistros: 0,
  etiqueta: [],
  id: 0,
  codigoSerial: '',
  idLoja: 0,
  idUsuario: 0,
  idTotvsInsumo: 0,
  idChecklistOperacaoItem: 0,
  responsavel: '',
  data: '',
  dataValidade: '',
  excluido: false,
  page: 0,
  limit: 10,
  order: 'dataValidade',
  direction: 'desc',
  term: ''
}

export function etiqueta(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_ETIQUETA':
      return {
        ...state,
        etiqueta: action.etiqueta,
        totalRegistros: action.totalRegistros
      }
    case 'ETIQUETA_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.etiqueta.id,
        codigoSerial: action.etiqueta.codigoSerial,
        idLoja: action.etiqueta.idLoja,
        idUsuario: action.etiqueta.idUsuario,
        idTotvsInsumo: action.etiqueta.idTotvsInsumo,
        idChecklistOperacaoItem: action.etiqueta.idChecklistOperacaoItem,
        responsavel: action.etiqueta.responsavel,
        data: action.etiqueta.data,
        dataValidade: action.etiqueta.dataValidade,
        excluido: action.etiqueta.excluido,
      }
    case 'ETIQUETA_UPDATED':
      return state
    case 'ETIQUETA_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term,
        data: state.data,
        dataValidade: state.dataValidade,
      }
    case 'ETIQUETA_CLEAR_ALL':
      return {
        ...state,
        etiqueta: []
      }
    case 'ETIQUETA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'ETIQUETA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'ETIQUETA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
