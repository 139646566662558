import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  withStyles,
  Button,
  Grid,
  MobileStepper,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import { retrospectivaAction } from '../../_actions'
import { history } from '../../utils'
import { withRouter, useLocation } from 'react-router-dom'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ShareIcon from '@material-ui/icons/Share';
import Lottie from 'react-lottie-player'
import lottieJson from '../../assets/animations/carregamento.json'
import html2canvas from "html2canvas";

const styles = (theme) => ({
  root: {
    height: '95dvh',
    width: '100dvw'
  },
  rootStepper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100dvw',
    height: '5dvh',
    minHeight: '50px',
    zIndex: 10
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#AC1E23',
    backgroundSize: 'contain !important',
    backgroundPosition: 'center !important',
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100dvh',
    width: '100dvw'
  },
  gridTitulo: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '19dvh',
    width: '100%',
    //// Add responsive styles
    //'@media (max-width: 768px)': { // For small screens (e.g., tablets or smaller)
    //  top: '20dvh',
    //}
  },
  gridMensagem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100%',
    textAlign: 'center',
    lineHeight: '1px !important',
  },
  gridRodape: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: '15dvh',
    width: '100%',
    // Add responsive styles
    //'@media (max-width: 768px)': { // For small screens (e.g., tablets or smaller)
    //  bottom: '15dvh',
    //}
  },
  textTitulo: {
    fontFamily: 'InterBoldFont',
    fontSize: 'clamp(14px, 1.1dvw, 48px)', // min, % of the viewport width preferred, max
    color: '#FFFFFF !important',
    textAlign: 'center',
    maxWidth: '20dvh',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    margin: '0 auto',
    lineHeight: '1.2',
  },
  textMensagem: {
    fontFamily: 'QuieroFont',
    fontSize: 'clamp(36px, 3.0dvw, 80px)', // min, % of the viewport width preferred, max
    fontWeight: 300,
    color: '#E11F1D',
    textAlign: 'center',
    margin: '0 auto',
    lineHeight: '1',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word'
  }
})

const DotsMobileStepper = ({ screenshotRef, classes, activeStep, setActiveStep, loading, hasTop }) => {

  const theme = useTheme();
  const [isIOS, setIsIOS] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {

    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
    if (/iPad|iPhone/.test(userAgent) && !window.MSStream) {
      setIsIOS(true);
    }
  }, []);

  const handleNext = () => {
    if (activeStep == 2 && !hasTop) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep == 4 && !hasTop) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const captureScreenshot = async () => {

    if (screenshotRef.current) {

      const canvas = await html2canvas(screenshotRef.current, {
        useCORS: true,
        backgroundColor: null,
      });
      const image = canvas.toDataURL("image/png");

      // Convert data URL to a Blob
      const blob = await (await fetch(image)).blob();
      const file = new File([blob], "screenshot.png", { type: "image/png" });

      if (navigator.share && navigator.canShare({ files: [file] })) {
        try {
          await navigator.share({
            title: "Retrospectiva do Quiero",
            text: "Olha só como foi o meu ano com o Quiero!",
            files: [file],
          });
          console.log("Screenshot shared successfully!");
        } catch (err) {
          console.error("Error sharing screenshot:", err);
        }
      } else {
        console.warn("Web Share API is not supported or cannot share files. Downloading instead.");
        downloadImage(image, "retrospectiva.png"); // Fallback to download
      }

    }
  };

  const downloadImage = (blob, fileName) => {
    const link = document.createElement("a");
    link.href = blob;
    link.download = fileName;
    link.click();
  };

  return (
    <MobileStepper
      variant="dots"
      steps={(hasTop ? 5 : 4)}
      position="static"
      activeStep={(!hasTop && activeStep == 4 ? 3 : activeStep)}
      className={classes.rootStepper}
      nextButton={
        <div>
          {isIOS &&
            <Button size="small" onClick={captureScreenshot} disabled={loading}>
              <ShareIcon />
            </Button>
          }
          <Button size="small" onClick={handleNext} disabled={activeStep === 4 || loading}>
            PRÓXIMO
            {theme.direction === 'rtl' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
          </Button>
        </div>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0 || loading}>
          {theme.direction === 'rtl' ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
          ANTERIOR
        </Button>
      }
    />
  );
}

const Retrospectiva = (props) => {
  const screenshotRef = useRef(null);
  const { classes } = props;
  const { match: { params } } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()

  const [backgroundImage, setBackgroundImage] = useState('/images/retrospectiva/retro1_v2.svg');
  const [titulo, setTitulo] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [mensagemFontSize, setMensagemFontSize] = useState('');
  const [mensagemRodape, setMensagemRodape] = useState('');

  const location = useLocation();
  const retrospectiva = useSelector(state => state.retrospectiva);

  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {

    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
    if (/Android|iPhone/.test(userAgent) && !window.MSStream) {
      setIsAndroid(true);
    }

    const preloadImages = async (file) => {
      const image = new Image();
      image.src = '/images/retrospectiva/' + file;
      await image.decode();
    };

    preloadImages('retro1_v2.svg');
    preloadImages('retro2_v2.svg');
    preloadImages('retro3_v2.svg');
    preloadImages('retro4_v2.svg');
    preloadImages('retro5_v2.svg');
  }, []);

  useEffect(() => {
    if (location != null) {
      const params = new URLSearchParams(location.search);
      const token = params.get("token");

      if (params && token) {
        dispatch(retrospectivaAction.clear());
        dispatch(retrospectivaAction.isLoading());

        params.delete("token");

        history.replace({
          pathname: location.pathname,
          search: params.toString(),
        });

        dispatch(retrospectivaAction.getRetrospectiva(token));
      }
    }
  }, [location])

  useEffect(() => {
    setBackgroundImage('/images/retrospectiva/retro' + (activeStep + 1) + '_v2.svg')

    if (retrospectiva) {
      setLoading(retrospectiva.isLoading);

      switch (activeStep) {
        case 0:
          setTitulo('NESTE ANO VOCÊ PONTUOU NO APP');
          setMensagem(retrospectiva.totalFidelizacoes)
          setMensagemFontSize('96px')
          setMensagemRodape('TOTALIZANDO ' + retrospectiva.pontuacao + ' PONTOS ACUMULADOS!');
          break;

        case 1:
          const lojaFav = retrospectiva.nomeLojaFavorita
          const hasSpace = lojaFav.split(" ").length > 1
          let fontSize = 0;
          let rate = 1.2;

          if (isAndroid && lojaFav.length > 16) {
            rate = 0.6;
          }

          if (hasSpace) {
            if (lojaFav.length <= 10) {
              fontSize = Math.max(32, 64 - lojaFav.length * rate)
            } else if (lojaFav.length <= 15) {
              fontSize = Math.max(32, 54 - lojaFav.length * rate)
            } else if (lojaFav.length <= 20) {
              fontSize = Math.max(32, 64 - lojaFav.length * rate)
            } else if (lojaFav.length <= 30) {
              fontSize = Math.max(28, 64 - lojaFav.length * rate)
            } else {
              fontSize = Math.max(28, 50 - lojaFav.length * rate)
            }
          } else {
            if (lojaFav.length <= 10) {
              fontSize = Math.max(32, 64 - lojaFav.length * rate)
            } else {
              fontSize = Math.max(32, 54 - lojaFav.length * rate)
            }
          }

          setTitulo('SEU QUIERO FAVORITO É');
          setMensagem(lojaFav);
          setMensagemFontSize(`${fontSize}px`)
          setMensagemRodape('VOCÊ ESTEVE EM ' + retrospectiva.totalLojas + ' LOJAS DIFERENTES!');
          break;

        case 2:
          setTitulo('VOCÊ PROVOU');
          setMensagem(retrospectiva.totalItens);
          setMensagemFontSize('96px')
          setMensagemRodape('ITENS DO NOSSO CARDÁPIO.');
          break;

        case 3:
          const topSplit = retrospectiva.top.split(' ')

          setTitulo('VOCÊ É');
          setMensagem(topSplit[0] + '\n' + topSplit[1]);
          setMensagemFontSize('64px')
          setMensagemRodape('CLIENTES DA LOJA DE ' + retrospectiva.nomeLojaTop + ". QUE DEMAIS, HEIN!");
          break;

        default:
          setTitulo('');
          setMensagem('');
          setMensagemRodape('');
          break;
      }
    }
  }, [retrospectiva, activeStep])

  return (
    <Grid container style={{ position: 'relative', height: '100dvh', overflow: 'hidden' }}>
      <Grid container className={classes.root}>
        {(loading ?
          <div style={{ width: '100dvw', height: '100dvh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#AC1E23', maxWidth: '100dvw', maxHeight: '100dvh', overflow: 'hidden' }}>
            <Lottie
              loop
              animationData={lottieJson}
              play
              style={{ width: '100%', height: '100%' }} // Adjusted to use percentages for responsiveness
            />
          </div>
          :
          <Grid container ref={screenshotRef} md={12} xs={12} sm={12} lg={12} className={classes.image} style={{ backgroundImage: `url(${backgroundImage})` }}>
            <Grid container md={12} xs={12} sm={12} lg={12} className={classes.container}>
              {(titulo != '' ?
                <Grid item md={12} xs={12} sm={12} lg={12} className={classes.gridTitulo} style={{ marginTop: 20 }}>
                  <Typography className={classes.textTitulo}>{titulo}</Typography>
                </Grid>
                :
                null
              )}

              {(mensagem != '' ?
                <Grid item md={12} xs={12} sm={12} lg={12} className={classes.gridMensagem}>
                  <Typography
                    className={classes.textMensagem}
                    style={{
                      fontSize: `${mensagemFontSize}`
                    }}
                  >
                    {mensagem}
                  </Typography>
                </Grid>
                :
                null
              )}

              {(mensagemRodape != '' ?
                <Grid item md={12} xs={12} sm={12} lg={12} className={classes.gridRodape} style={{ marginBottom: 20 }}>
                  <Typography className={classes.textTitulo}>{mensagemRodape}</Typography>
                </Grid>
                :
                null
              )}

            </Grid>
          </Grid>
        )}
      </Grid>
      <DotsMobileStepper screenshotRef={screenshotRef} classes={classes} activeStep={activeStep} setActiveStep={setActiveStep} loading={loading} hasTop={retrospectiva.top != null} />
    </Grid>
  )

}

Retrospectiva.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  const { loggingIn } = state.authentication
  return {
    loggingIn
  }
}
const connectedRetrospectivaPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Retrospectiva))
)
export { connectedRetrospectivaPage as Retrospectiva }
