import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { produtoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  Paper,
  Switch,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TablePagination
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const Produto = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { produto } = props.produto
  const { totalRegistros } = props.produto
  const { page, limit, term, filtroNovidade, filtroTrocavel, filtroLimiteTrocaClientes, filtroFatorPontuacao, direction, order } = useSelector(state => state.produto);
  const { idLoja } = useSelector(state => state.usuarioLoja);

  const [debounceTerm] = useDebounce(term, 1000)

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm, filtroNovidade, filtroTrocavel, filtroLimiteTrocaClientes, filtroFatorPontuacao]);

  useEffect(() => {
    const totalPages = Math.round(totalRegistros / limit)

    handleChangePage(null, (totalPages < page ? totalPages : page));
  }, [totalRegistros])

  useEffect(() => {
    handleChangePage(null, 0);
    fetchData();
  }, [idLoja]);

  useEffect(() => {
    handleChangePage(null, page);
  }, []);

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'nome',
      Direction: direction || 'asc',
      IdLoja: idLoja || 0,
      FatorPontuacao: filtroFatorPontuacao || false,
      Novidade: filtroNovidade || false,
      Trocavel: filtroTrocavel || false,
      LimiteTrocaClientes: filtroLimiteTrocaClientes || false,
    };

    dispatch(produtoAction.getProduto(filterModel, true));
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Produto?',
      description: 'Essa operação é irreversível',
      disabled: props.produto.isLoading,
      confirmationText: props.produto.isLoading ? 'Excluindo produto...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(produtoAction.deleteProdutoById(id))
    })
  }

  const handleChange = prop => event => {
    dispatch(produtoAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(produtoAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeProp = (prop, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.produto.page = 0;
    dispatch(produtoAction.onChangeProps(prop, fakeEvent))
  }

  return (
    <Page className={classes.root} title="Produtos">
      <AccessControl
        rule={'produtos'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            <TitleContent
              rule={'produtos.create'}
              length={undefined !== produto && produto.length > 0 ? produto.length : 0}
              subTitle={'GERÊNCIA DE PRODUTO'}
              title={'Lista de Produtos'}
              href={'/produto'}
            />
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={6} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  onChange={(event) => handleChangeProp('term', event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xl={2} lg={2} md={6} xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filtroFatorPontuacao}
                      onChange={handleChange('filtroFatorPontuacao')}
                      color="primary"
                    />
                  }
                  label="Fator Pontuação"
                />
              </Grid>
              <Grid item xl={2} lg={2} md={6} xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filtroNovidade}
                      onChange={handleChange('filtroNovidade')}
                      color="primary"
                    />
                  }
                  label="Novidade"
                />
              </Grid>
              <Grid item xl={2} lg={2} md={6} xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filtroTrocavel}
                      onChange={handleChange('filtroTrocavel')}
                      color="primary"
                    />
                  }
                  label="Trocável"
                />
              </Grid>
              <Grid item xl={2} lg={2} md={6} xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filtroLimiteTrocaClientes}
                      onChange={handleChange('filtroLimiteTrocaClientes')}
                      color="primary"
                    />
                  }
                  label="Limite de Troca"
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== produto && produto.length
                    ? produto.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.nome}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'produtos.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/produto/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'produtos.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
Produto.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    produto: state.produto
  }
}
const connectedProdutoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Produto))
)
export { connectedProdutoPage as Produto }
